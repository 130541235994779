/* eslint-disable camelcase */
// 挽留页面
import React, { useEffect, useState } from 'react'
import styles from './retain-page.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import indexApi from '@/apis'
import { selectUserInfo } from '@/store/user'
import { useAppSelector } from '@/store/hooks'
import { SensorsType } from '@/constants/sensorsBurying'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import sensors from 'sa-sdk-javascript'
import { Toast, Image, Divider, Button } from 'antd-mobile'
import Header from '@/components/header/header'
import { ENTER_AGREEMENT } from '@/constants/storage'
import { Storage } from '@bihu/common-js'
import { randomNum } from '@/utils/utils'
import Icon from '@/components/icon/icon'
import { useSensors } from '@/hooks/use-sensors'
import { EntranceType } from '@/types/apis'

interface Props { }


interface ProtocolType {
  id: string,
  name: string | null,
  protocolType: number | null
  partnerProtocolList?:any
}


interface InstitutionProduct {
  id: number,
  productCompany: string | null,
  productName: string | null,
  partnerProtocolList:ProtocolType[] | null
}

const RetainPage: React.FC<Props> = () => {
  const navigate = useNavigate()
  const [rqResult, setRqResult] = useState<any>()
  const [search] = useSearchParams()
  // 当前勾选的多产品
  const [checkInstitution, setCheckInstitution] = useState<number[]>([])
  const userInfo = useAppSelector(selectUserInfo)
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const [details, setDetails] = useState<any>({})
  // 当前操作第几回授权
  const [authNum, setAuthNum] = useState(0)
  // 随机服务人数
  const [randomNums, setRandomNums] = useState<{ serviceNum: number | null, servicePersent: number | null }>({
    serviceNum: null,
    servicePersent: null
  })
  const [isSubmitWaiting, setIsSubimtWaiting] = useState(false)
  const { sensorsVisitAuthorizePage } = useSensors()

  const introduceList = [
    {
      icon: 'CarryOut',
      label: '提交需求',
      placeholder: '已提交'
    },
    {
      icon: 'Audit',
      label: '系统初审',
      placeholder: '待领取额度'
    },
    {
      icon: 'Phone',
      label: '致电确认',
      placeholder: '待完成'
    },
    {
      icon: 'MoneyCollect',
      label: '审核下款',
      placeholder: '待完成'
    }
  ]

  // 提交授权
  const submitApplication = async() => {
    try {
      setIsSubimtWaiting(true)
      let protocolIds = rqResult.protocols?.map((item:any) => {
        return item.id
      })

      // 如果匹配到的是H5产品，则走H5产品的申请、联登流程
      if (rqResult.thirdpartyType === 1) {
        handleH5Product(rqResult.id, 0, protocolIds)
        return
      }
      // 前端日志上报
      const reportParam = `uid:${userInfo?.uid},adChannelCode:${details.adChannelCode},event:授权点击`
      indexApi.reportLog(reportParam)
      // 前端神策埋点 - 用户点击授权
      sensorsVisitAuthorizePage(SensorsType.click_authorize, rqResult, checkInstitution)
      // 信息授权
      const res:any = await indexApi.confirmAuthorize({
        productId: rqResult.id,
        protocolIds,
        agree: true,
        adChannelCode: details.adChannelCode,
        isHuaWeiPhone: details.isHuaWeiPhone,
        apiChannelCode: '',
        orderNum: authNum, //第几回授权
        serialNo: rqResult.serialNo,
        productIds: checkInstitution
      })
      setIsSubimtWaiting(false)
      handleAduitSuccess(res)
    } catch (error) {
      setIsSubimtWaiting(false)
      console.log(error)
    }
  }

  // 修改页面url
  const reFreshPageUrl = (newRqResult:any, tempAuthNum: any) => {
    const pageUrl = window.location.origin + window.location.pathname
    let urlOptions = {
      ...details,
      rqResult: newRqResult,
    }
    window.history.replaceState(null, 'null', `${pageUrl}?options=${encodeURIComponent(JSON.stringify(urlOptions))}&authNum=${tempAuthNum}`)
  }

  const handleAduitSuccess = async(res: any) => {
    // 正常授权且有结果(撞库通过)
    if (res && res.applyStatus !== 2) {
      // 如果是API转H5类型，则直接跳转第三方链接
      if (res.thirdpartyType === 4 && res.thirdpartyTargetUrl) {
        await indexApi.apiH5JumpUrlSuccessFlag({
          uid: userInfo?.uid,
          productId: rqResult.id,
          productName: rqResult.productName,
          thirdpartyTargetUrl: res.thirdpartyTargetUrl
        })
        window.location.href = res.thirdpartyTargetUrl
        return
      }

      // 如果授权失败，但是有返回下一个产品，就重新授权
      if (res.applyStatus === 4 && res.productNextMatchDTO) {
        // productNextMatchDTO没有返回serialNo，需要保存
        if (rqResult.serialNo) {
          res.productNextMatchDTO.serialNo = rqResult.serialNo
        }
        // 如果是其它产品，则更新本页面信息
        setRqResult(res.productNextMatchDTO)
        setAuthNum(res.productNextMatchDTO.orderNum)
        Toast.show('申请名额已满，您的匹配机构已更新，请继续操作')
        reFreshPageUrl(res.productNextMatchDTO, res.productNextMatchDTO.orderNum)
        return
      }

      navigate(`/fill-information-three/success-page?options=${encodeURIComponent(JSON.stringify(res))}`)
      return
    }

    // 结果页
    // 走这里说明是重复授权或者授权失败，重复授权也需要判断res
    const options:any = res ? res : { rePageShowType: details.adChannelCode === '9o0w2B' && details.isHuaWeiPhone ? 2 : 0 }
    navigate(`/fill-information-three/success-page?options=${encodeURIComponent(JSON.stringify(options))}`)
  }

  // 处理H5产品逻辑
  const handleH5Product = async(productId:any, entranceType:EntranceType, protocolIds:any) => {
    // 申请产品
    let applyProductRes:any = await indexApi.applyFor({
      productId,
      entranceType,
      appH5: 2
    })
    // 判断是否需要联登
    if (applyProductRes?.unionLoginFlag === 1) {
      try {
        const authH5ProductRes:any = await indexApi.authH5Product({
          productId,
          protocolIds: `${protocolIds}${''}`,
          agree: true,
          entranceType,
          appH5: 2
        })
        if (authH5ProductRes) {
          window.location.href = authH5ProductRes
        } else {
          navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify({ rePageShowType: details.adChannelCode === '9o0w2B' && details.isHuaWeiPhone ? 2 : 0 }))}`)
        }
      } catch (error) {
        navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify({ rePageShowType: details.adChannelCode === '9o0w2B' && details.isHuaWeiPhone ? 2 : 0 }))}`)
      }
    } else {
      window.location.href = applyProductRes.thirdpartyTargetUrl
    }
  }

  // 随机生成数字，并存储本地防止刷新数据变化太大
  const getRandomNum = () => {
    let list = localStorage.getItem('randomNumList')
    let randomNumList
    if (list) {
      randomNumList = JSON.parse(list)
    } else {
      randomNumList = {
        serviceNum: randomNum(15000, 30000),
        servicePersent: randomNum(950, 1000) / 10
      }
      localStorage.setItem('randomNumList', JSON.stringify(randomNumList))
    }
    setRandomNums(randomNumList)
  }

  useEffect(() => {
    const pageOptions: any = search.get('options')
    getRandomNum()
    let tempDetails
    let checkProduct
    try {
      tempDetails = JSON.parse(decodeURIComponent(pageOptions)) || {}
    } catch (err) {
      tempDetails = JSON.parse(pageOptions) || {}
    }
    // 如果是多产品分发，那么给产品默认添加勾选
    if (tempDetails.rqResult?.mayAuthProductList?.length > 0) {
      checkProduct = tempDetails.rqResult?.mayAuthProductList?.map((product: InstitutionProduct) => {
        return product.id
      })
      setCheckInstitution(checkProduct)
    }
    setDetails(tempDetails)
    setRqResult(tempDetails.rqResult)
  }, [])

  return (
    <div className={styles.retainPage}>
      <Header />
      <div className={styles.productCard}>
        <div className={styles.multipleProcessContentItem}>
          <div className={styles.multipleProcessContentItemLeft}>
            <div>
              {
                rqResult?.thirdpartyType !== 7 || !rqResult.yqlWholeProcessBumpVO?.partnerLogo ? <>
                  <img className={styles.contentItemIcon} src={require('@imgs/information/loan-platform.png')} />
                </> : <>
                  <Image className={styles.contentItemIcon} fit="cover" style={{
                    marginRight: '10px',
                    borderRadius: 6
                  }}
                  src={rqResult.yqlWholeProcessBumpVO?.partnerLogo}
                  fallback={<img className={styles.contentItemIcon}
                    alt="contentItemIcon"
                    src={require('@imgs/information/loan-institutions.png')}
                  />}
                  />
                  {/* <img className={styles.contentItemIcon} src={rqResult.yqlWholeProcessBumpVO?.partnerLogo} /> */}
                </>
              }
            </div>
            {
              channelValue?.includes('noListMainBody')
                ? <div className={styles.multipleProcessContentItemInfo}>
                  <span>
                    { rqResult?.thirdpartyType === 7 ? rqResult?.productName : '有钱钱包' }
                  </span>
                  <div>
                    {
                      rqResult?.thirdpartyType === 7
                        ? <>{rqResult?.yqlWholeProcessBumpVO?.partnerProductName ?? ''}</>
                        : <>{rqResult?.productName ?? ''}</>
                    }
                  </div>
                </div>
                : <div className={styles.multipleProductInfo}>
                  <div className={styles.multipleProductInfoTop}>
                    <span className={styles.productName}>
                      {rqResult?.thirdpartyType === 7 ? rqResult?.yqlWholeProcessBumpVO?.partnerProductName ?? '' : rqResult?.productName ?? ''}
                    </span>
                    {/* { rqResult?.thirdpartyType === 7
                          && <div className={styles.platformName}>服务平台：{ rqResult?.productName}</div>
                      } */}
                  </div>
                  <span className={styles.partnerCompanyName}>
                    {rqResult?.thirdpartyType === 7 ? rqResult?.yqlWholeProcessBumpVO?.partnerCompanyName ?? '' : rqResult?.productCompany ?? ''}
                  </span>
                </div>
            }
          </div>
        </div>
        <Divider />
        <div className={styles.productIntroduce}>
          <div className={styles.productIntroduceItem}>
            <div className={styles.productInfo}>{randomNums.serviceNum}</div>
            <div className={styles.productName}>服务人数</div>
          </div>
          <Divider style={{ height: '30px' }} direction="vertical"/>
          <div className={styles.productIntroduceItem}>
            <div className={styles.productInfo}>车 房 公积金</div>
            <div className={styles.productName}>擅长领域</div>
          </div>
          <Divider style={{ height: '30px' }} direction="vertical"/>
          <div className={styles.productIntroduceItem}>
            <div className={styles.productInfo}>{randomNums.servicePersent}%</div>
            <div className={styles.productName}>服务人数</div>
          </div>
        </div>
        <div className={styles.maskBox}>
          <img style={{ opacity: '0.5' }} src={require(`@/assets/imgs/fill-infomation-three/${!channelValue.includes('jufu') ? 'yqqb-' : ''}card-bg.png`)} alt="" />
        </div>
      </div>
      <div className={styles.introduceBox}>
        <div className={styles.introduceTitle}>
          <div className={styles.line}></div>
          <div className={styles.introduceTitleText}>4步轻松拿钱</div>
          <div className={styles.line} style={{ transform: 'rotate(-180deg)' }}></div>
        </div>
        <div className={styles.introduceList}>
          {introduceList.map(item => {
            return <div key={item.icon} className={styles.introduceItem}>
              <div className={styles.introduceItemHeader}>
                <Icon type={item.icon} size={20} className={styles.introduceItemHeaderIcon}></Icon>
                <div className={styles.introduceItemHeaderText}>{ item.label}</div>
              </div>
              <span>{item.placeholder}</span>
            </div>
          })}
        </div>
        <div className={styles.specialReminderBox}>
          <div className={styles.title}>特别提醒</div>
          <div>
            1.此额度需要机构/资方复审，业务员预计一小时内与您取得联系，请保持电话畅通
          </div>
          <div>
            2.致电人员为平台指定三方金融机构，来电请核对身份！
          </div>
          <div>
            3.平台承诺不会像用户收取任何费用，以官方名义收费行为都是诈骗！
          </div>
          <div>
            4.下款前以<span style={{ color: '#F63E48' }}>"卡号错误""验资""解冻"</span>等任何理由收费的行为，均为诈骗！
          </div>
        </div>
      </div>
      <div className={styles.btnBox}>
        <Button className={styles.btn} disabled={isSubmitWaiting} onClick={submitApplication} size="large" color="primary">继续领取</Button>
      </div>
    </div>
  )
}

export default RetainPage

import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import styles from './form-choose-item.module.scss'
import { OptionType } from '../../options'
interface Props{

    /** 是否支持展开 */
    isSupportExpansion?: boolean,

    /** 是否支持多选 */
    isSupportMultiple?: boolean,

    /** 选中的值 */
    value?: any,

    /** 改变事件 */
    onChange: (obj:OptionType) => void,

    /** 名称 */
    name: string,

    /** 选项数组 */
    options: OptionType[]

    /** 宽度 */
    width?: string

    /** 换行数字 */
    warpNum?: number,

    /**  */
}
// 选择选项
const ChooseOption:React.FC<Props> = props => {
  const {
    isSupportMultiple = false,
    value,
    name,
    options,
    onChange,
    width,
    // eslint-disable-next-line no-magic-numbers
    warpNum = 3
  } = props

  const [label, setLabel] = useState<string>()

  // 根据传入的值回显
  useEffect(() => {
    if (value || value === '0') {
      const temp = options.find(item => item.value === value)
      if (temp) {
        setLabel(temp.label)
      }
    }
  }, [value, options, isSupportMultiple])

  // 点击选项
  const selectOption = (item:OptionType) => {
    setLabel(item.label)
    onChange(item)
  }

  return (
    <div className={styles.formChooseItem}>
      <div className={styles.formChooseItemTitle}>{ name }</div>
      <div className={styles.options}>
        {options.map((item, index) => {
          return <div
            key={index}
            className={classNames(styles.option, item.label === label && styles.active)}
            onClick={() => selectOption(item)}
            style={{ width: width || '' }}
          >
            {item.label}
          </div>
        })}
      </div>
    </div>
  )
}
export default ChooseOption
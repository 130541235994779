/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import Header from '@/components/header/header'
import styles from './information-agreement.module.scss'
import classNames from 'classnames'
import { getParams, formatTime } from '@bihu/common-js'
import getHostNameObj from '@/hooks/get-host-name-obj'
import indexApi from '@/apis/index'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { CompanyType } from '@/constants/common'

interface Props{
  isResultPage?: boolean
}

export interface LoginInfo {
  uid: string,
  realName: string,
  mobile: string,
  loginAt:string
}
// 注册协议
const InformationAgreement: React.FC<Props> = props => {
  const { isResultPage = false } = props
  const { channelValue } = useSelector((state: RootState) => state.channel)
  const [isShowHeader, setIsShowHeader] = useState(false)
  const [uid, setUid] = useState<string>('')
  const [loginInfo, setLoginInfo] = useState<LoginInfo | undefined>()

  // 获取用户信息
  const getLoginInfo = async(id: string) => {
    const res = await indexApi.getInformationProtocolById(id)
    setLoginInfo(res)
  }

  useEffect(() => {
    if (getParams(undefined, 'isShowHeader')) {
      setIsShowHeader(true)
    }
    if (getParams(undefined, 'uid')) {
      let id = getParams(undefined, 'uid')
      if (id) {
        setUid(id)
        getLoginInfo(id)
      }
    }
  }, [])

  const getAgreementCompany = () => {
    switch (true) {
      case channelValue?.includes('hbjf'):
        return '本公司'
      case channelValue?.includes('zhonganXy'):
        return CompanyType.ZAXY_COMPANY.company
      case channelValue?.includes('jfxd'):
        return CompanyType.JFXD_COMPANY.company
      case channelValue?.includes('shenzhenJkr'):
        return CompanyType.JKR_COMPANY.company
      case channelValue?.includes('jiezhanggui'):
        return '本平台'
      default:
        return CompanyType.YQQB_COMPANY.company
    }
  }

  // 有钱钱包个人信息共享授权协议
  const youqianlaiRegister = () => {
    return (
      <>
        <p>
        《个人信息共享授权协议》（以下简称“本协议”）是由【{ getAgreementCompany() }】（以下简称“我们”）与用户（以下简称您”）就个人信息共享所订立的有效合约，你通过本页面点击确认，即表示您同意接受本协议的全部约定内容以及本协议有关的已经发布或将来可能发布的各项规则、页面展示、操作流程、公告或通知（以下统称“规则”）。
        </p>
        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>一、当您通过本页面使用我们提供的【第三方产品推荐】服务时，为了增加您的申请贷款通过机会，您的个人信息会进行共享。为了您的合法权益，请您在使用本服务前务必仔细阅读本协议的全部内容（特别是以粗体/下划线标注的内容)。如果您不同意本协议的任意内容，或者无法准确理解本协议任何条款的含义，请不要进行授权及后续操作。</p>
        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>
        二、您同意本协议以数据电文形式订立。如您通过网络页面点击“同意”或“注册”或“申请”或“接受用户授权协议”（以网页显示为准），即视为您已阅读理解本协议的全部内容并同意我们出于相关页面所述目的，将您的个人信息通过加密通道的方式提供给为您提供【账号注册、授信审核、货款】等服务的其他个人信息处理者（以下简称“第三方”），第三方将通过包括且不仅限于短信或致电的方式告知并为您提供服务，账号能否注册成功、授信审核能否通过、能否申请贷款等相关服务以您和第三方的约定为准。
        </p>
        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>
          三、本协议中共享的个人信息含个人敏感信息及其他个人信息，包括但不限于：
        </p>
        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>
        1.您的身份信息，包括但不限于您的姓名、性别、年龄、户籍、现居住住址、电话号码、身份证号、工作信息、学历、婚姻状况、紧急联系人、设备信息、生物特征、通讯录等信息；
        </p>
        <p className={styles.bold}>
        2.您的信用信息，包括但不限于您的征信记录和信用报告；
        </p>
        <p className={styles.bold}>
        3.您的财产信息，包括但不限于您的财税信息、房产信息、车辆信息、基金、保险、股票、信托、债务、收入情况、银行卡号、开户行、开户地址等投资理财信息和负债信息等；
        </p>
        <p className={styles.bold}>
        4.其他实现服务所需的必要信息等。
        </p>
        <div className={styles.emptyLine}></div>
        <p>
          <span className={styles.bold}>四、您同意并授权我们将您的上述信息共享给第三方，</span>但我们与第三方在开展上述信息共享前，会严格要求第三方对在信息共享中所获取的您的上述信息予以保密并且依照相关法律法规或者本协议的约定使用您的上述信息。
        </p>
        <div className={styles.emptyLine}></div>
        <p>
          <span className={styles.bold}>五、您同意并授权我们将您的上述信息共享给第三方，</span>系统会依据用户服务协议将您填写的个人信息共享给第三方，如当前第三方申请已满等原因无法为您提供服务，系统会继续为您匹配其它第三方直至匹配成功，并自动将您的个人信息共享给该匹配成功的第三方。如果您不希望向第三方提供上述信息，<span className={styles.bold}>请勿点击授权。</span>
        </p>
        <div className={styles.emptyLine}></div>
        <p>
        六、您理解，第三方服务由相应的第三方就以上服务及处理您个人信息的安全性独立承担责任。请您在授权同意前，仔细阅读并理解第三方的服务协议与隐私政策。<span className={styles.bold}>因该第三方服务或其处理您的个人信息产生的纠纷，或第三方服务违反相关法律法规或者协议的约定，或您在使用第三方服务过程中遭受了损失，请您与第三方协商解决。</span>
        </p>
        <div className={styles.emptyLine}></div>
        <p>
        七、您同意，凡因本协议引起的任何与之有关的争议、纠纷，首先通过友好协商的方式解决，如协商不能达成一致的，<span className={styles.bold}>任何一方均可向本平台所在地有管辖权的人民法院提起诉讼。</span>
        </p>
        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>八、您同意本协议中的授权不可撤回或撤销。</p>
        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>九、本协议未尽事宜参照《用户注册协议》与《隐私协议》执行；若《用户注册协议》、《隐私协议》与本协议有冲突的，以本协议为准。</p>
        <div className={styles.emptyLine}></div>
        {
          uid && <>
            <p className={styles.bold}>手机号:  {loginInfo?.mobile}</p>
            <div className={styles.emptyLine}></div>
            <p className={styles.bold}>姓名:  {loginInfo?.realName}</p>
            <div className={styles.emptyLine}></div>
            <p className={styles.bold}>uid:  {loginInfo?.uid}</p>
            <div className={styles.emptyLine}></div>
            <p className={styles.bold}>日期:  {loginInfo?.loginAt && formatTime(loginInfo.loginAt, 'YYYY-MM-DD hh:mm:ss')}</p>
          </>
        }
      </>
    )
  }


  return (
    <div style={{ background: isResultPage ? '#F5F5F5' : '#FFFFFF' }} className={classNames(styles.registerAgreement, isShowHeader && styles.isShowHeader)}>
      {
        isShowHeader && <Header title={ channelValue.includes('meta') ? '合作机构个人信息授权书' : ' 个人信息共享授权协议'} />
      }
      {
        youqianlaiRegister()
      }
    </div>
  )
}

export default InformationAgreement
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useRef } from 'react'
import { Button, Toast, Popup } from 'antd-mobile'
import styles from './authorize-commom-page.module.scss'
import Header from '@/components/header/header'
import classNames from 'classnames'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { isIOS, isAndroid, isMobile, Storage } from '@bihu/common-js'
import { ENTER_AGREEMENT, TOKEN } from '@/constants/storage'
import indexApi, { GetProductInfoWidthStatus } from '@/apis'
import ProtocolIdsConfirm, { Comfirm2PropsHandle } from '../protocolIds-confirm-2/protocolIds-confirm-2'
import ApiToH5Skin from './components/api-to-h5-skin/api-to-h5-skin'
import { selectUserInfo } from '@/store/user'
import { useAppSelector } from '@/store/hooks'
import moment from 'moment'
import indexApiInfo from '@/apis/index'
import InstitutionList from '../institution-list/institution-list'
import { RightOutline } from 'antd-mobile-icons'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { SensorsType } from '@/constants/sensorsBurying'
import { CompanyType } from '@/constants/common'
import { useSensors } from '@/hooks/use-sensors'
import { AuthResponese, MayAuthProductList, PartnerProtocolList, ProductNextMatchDTO, Protocols } from '@/types/apis/user'
import { EntranceType } from '@/types/apis'
import { ThirdpartyType } from '@/constants/product-enum'

interface Props{ }


interface ExpandAuthResponese extends AuthResponese {
  jumpUrl?: string,
}

// url 携带参数详情
interface Details {
  adChannelCode?: string,
  isHuaWeiPhone?: boolean,
  applyLimit?: number,
  workCity?: string,
  rqResult?:GetProductInfoWidthStatus
}
interface InstitutionProtocol {
  id?: number | null | string,
  name?: string | null,
  url?:string | null,
  protocolList?: PartnerProtocolList[]
  protocolType?: number | null;
}


const Authorize: React.FC<Props> = () => {
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const ProtocolIdsConfirmModalRef = useRef<Comfirm2PropsHandle | null>(null)
  const [search] = useSearchParams()
  const userInfo = useAppSelector(selectUserInfo)

  const [details, setDetails] = useState<Details>()
  const [rqResult, setRqResult] = useState<GetProductInfoWidthStatus | ProductNextMatchDTO>()
  const [otherProtocols, setotherProtocols] = useState<Array<PartnerProtocolList | Protocols>>([])
  const [youqianlaiProtocol, setYouqianlaiProtocol] = useState<Protocols>()
  const [agreementContent, setAgreementContent] = useState('')
  // 是否显示弹出层
  const [protocolIdsVisible, setProtocolIdsVisible] = useState(false)
  // 如果是匹配多产品，则显示匹配的多产品协议列表
  const [institutionProtocol, setInstitutionProtocol] = useState<Array<InstitutionProtocol>>([])
  // 多产品树状协议列表下，当前点击的树状节点
  const [checkProtocol, setCheckProtocol] = useState<string | null>()
  // 当前勾选的多产品
  const [checkInstitution, setCheckInstitution] = useState<number[]>([])
  const { sensorsVisitAuthorizePage } = useSensors()

  const navigate = useNavigate()

  const [token, setToken] = useState('')
  const [apiChannelCode, setApiChannelCode] = useState('')
  const [serialNo, setSerialNo] = useState('')
  const [isSpecialChannel, setIsSpecialChannel] = useState<boolean>(false)
  const [jumpUrl, setJumpUrl] = useState('')
  // 当前操作第几回授权
  const [authNum, setAuthNum] = useState < number | null>(0)
  let urlRqResult = search.get('rqResult')

  useEffect(() => {
    let urlApiChannelCode = search.get('apiChannelCode')
    let urlSerialNo = search.get('serialNo')
    let urlToken = search.get('token')
    let urlJumpUrl = search.get('jumpUrl')
    //设置authNum
    let urlAuthNum = search.get('authNum')
    if (urlAuthNum) {
      setAuthNum(Number(urlAuthNum))
    }
    if (urlApiChannelCode) {
      setSerialNo(urlSerialNo || '')
      setApiChannelCode(urlApiChannelCode)
      localStorage.setItem('ad_channel_code', urlApiChannelCode)
      if (urlJumpUrl) {
        const tempJumpUrl = JSON.parse(decodeURIComponent(urlJumpUrl))
        setJumpUrl(tempJumpUrl)
      }
      if (urlApiChannelCode === 'kxj01' || urlApiChannelCode === 'kxj02') {
        setIsSpecialChannel(true)
      }
      if (urlToken) {
        Storage.set(TOKEN, urlToken)
        setToken(urlToken)
      }
    } else {
      const pageOptions = search.get('options')
      let tempDetails
      let checkProduct
      if (pageOptions) {
        try {
          tempDetails = JSON.parse(decodeURIComponent(pageOptions)) || {}
        } catch (err) {
          tempDetails = JSON.parse(pageOptions) || {}
        }
      }
      // 如果是多产品分发，那么给产品默认添加勾选
      if (tempDetails.rqResult?.mayAuthProductList?.length > 0) {
        checkProduct = tempDetails.rqResult?.mayAuthProductList?.map((product: MayAuthProductList) => {
          return product.id
        })
        setCheckInstitution(checkProduct)
      }
      // 前端神策埋点 - 用户访问授权页
      sensorsVisitAuthorizePage(SensorsType.visit_authorization_page, tempDetails.rqResult)
      setDetails(tempDetails)
      setRqResult(tempDetails.rqResult)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 应用在API采量授权页重刷的场景
  const reVisitApiAuthPage = () => {
    if (urlRqResult) {
      const tempRqResult = JSON.parse(decodeURIComponent(urlRqResult))
      // 前端埋点-用户访问授权页
      sensorsVisitAuthorizePage(SensorsType.visit_authorization_page, tempRqResult)
      setRqResult(tempRqResult)
      setDetails({
        applyLimit: tempRqResult.applyLimit
      })
    }
  }

  // 如果是麦吉优则修改
  useEffect(() => {
    let newProtocols: Array<PartnerProtocolList | Protocols> = []
    let protocolTreeList:Array<InstitutionProtocol> = []
    // eslint-disable-next-line array-callback-return
    rqResult?.protocols && rqResult?.protocols.forEach((item:Protocols) => {
      if (item.protocolType !== 1) {
        newProtocols.push(item)
        protocolTreeList.push(item)
      } else if (item.protocolType === 1) {
        if (channelValue?.includes('mjy') || channelValue?.includes('hbjf')) {
          item.name = '用户个人信息共享授权协议'
        }
        // 如果是聚客融渠道的联登协议，需要将协议名改为聚客融联登协议
        if (channelValue?.includes('jufu') && item.name === '有钱来联登默认协议') {
          item.name = '聚客融联登默认协议'
        }
        setYouqianlaiProtocol(item)
      }
    })


    // 针对有钱来全流程类型的合作方式，需要把第三方协议并入合作机构协议中展示
    if (rqResult && rqResult.thirdpartyType === 7) {
      if (rqResult.yqlWholeProcessBumpVO && rqResult.yqlWholeProcessBumpVO.partnerProtocolList) {
        newProtocols = [
          ...newProtocols,
          ...rqResult.yqlWholeProcessBumpVO.partnerProtocolList,
        ]
        protocolTreeList.push({
          id: Math.random(),
          name: rqResult.productName || '合作机构协议',
          protocolList: rqResult.yqlWholeProcessBumpVO.partnerProtocolList,
        })
      }
    }
    // 如果是多产品，那么就将展示多产品形式的树状协议列表
    if (rqResult && rqResult?.mayAuthProductList && rqResult?.mayAuthProductList?.length > 0) {
      rqResult?.mayAuthProductList.forEach((product: MayAuthProductList) => {
        if (product.partnerProtocolList && product.partnerProtocolList?.length > 0) {
          return protocolTreeList.push({
            id: product.id,
            name: product.productName,
            protocolList: product.partnerProtocolList
          })
        }
      })
    }
    // 聚客融渠道不展示合作机构个人信息授权书
    if (protocolTreeList.length > 0 && channelValue?.includes('jufu')) {
      protocolTreeList = protocolTreeList.filter(item => item?.name !== '合作机构个人信息授权书')
      newProtocols = newProtocols.filter(item => item?.name !== '合作机构个人信息授权书')
    }
    setInstitutionProtocol(protocolTreeList)
    setotherProtocols(newProtocols)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rqResult])

  useEffect(() => {
    async function getAgreementInfo() {
      const res = await indexApi.getAgreementInfo(youqianlaiProtocol?.id || '', rqResult?.id)
      if (channelValue?.includes('jufu')) {
        // 如果是快手渠道，将公司主题改为广州市聚富互联网小额贷款有限公司
        if (channelValue?.includes('jfxd')) {
          res.content = res.content.replace(/广州虫洞跳跃信息科技有限公司|广州市虫洞科技有限公司/g, CompanyType.JFXD_COMPANY.company)
        } else {
          res.content = res.content.replace(/广州虫洞跳跃信息科技有限公司|广州市虫洞科技有限公司/g, CompanyType.JKR_COMPANY.company)
        }
        // 聚客融渠道，h5类型，将联登协议内容进行修改
        if (rqResult && rqResult.thirdpartyType === 1) {
          res.content = res.content.replace(/广州海珠/g, '深圳龙华')
          // 在【】前面插入【广州市虫洞科技有限公司】
          res.content = res.content.replace(/传输给\s*/, '传输给【广州市虫洞科技有限公司】')
        }
      }
      if ((channelValue?.includes('jiezhanggui'))) {
        // 替换个人信息授权书主体
        res.content = res.content.replace(/广州虫洞跳跃信息科技有限公司|广州市虫洞科技有限公司/g, '本平台')
      }
      if ((channelValue?.includes('mjy') || channelValue?.includes('hbjf')) && youqianlaiProtocol?.protocolType === 1) {
        let content = await mjyUserAgreement()
        setAgreementContent(content)
      } else {
        setAgreementContent(res.content)
      }

    }

    if (youqianlaiProtocol) {
      getAgreementInfo()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [youqianlaiProtocol])

  useEffect(() => {
    async function getFomrSubmitStatus() {
      const res = await indexApi.getFomrSubmitStatus({
        adChannelCode: apiChannelCode,
        serialNo
      })

      // 前端埋点-用户访问授权页
      sensorsVisitAuthorizePage(SensorsType.visit_authorization_page, res)
      setDetails({
        applyLimit: res.applyLimit
      })
      setRqResult(res)
    }

    // 说明当前路由已被修改，从URl中获取修改后的数据
    if (apiChannelCode && authNum && authNum > 0) {
      reVisitApiAuthPage()
    } else if (token && apiChannelCode) {
      getFomrSubmitStatus()
      let devicePlatForm = 3
      if (isAndroid) {
        devicePlatForm = 1
      }
      if (isIOS) {
        devicePlatForm = 2
      }
      indexApi.reportPhoneType(devicePlatForm)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, apiChannelCode, serialNo])

  // 选择协议
  const [checked, setChecked] = useState(false)

  // 切换选中协议
  const checkedTaggle = () => {
    setChecked(!checked)
  }

  // 按钮loading
  const [btnLoading, setBtnLoading] = useState(false)

  // 跳转页面
  const openOtherView = (url: string) => {
    Storage.set(ENTER_AGREEMENT, true)
    navigate(url)
  }

  const submitApplication = async(isNeedChecked = true) => {
    try {
      if (!checked && isNeedChecked) {
        ProtocolIdsConfirmModalRef.current?.init({
          youqianlaiProtocol,
          otherProtocols,
          productId: rqResult?.id,
        })
        return
      }
      let protocolIds = rqResult?.protocols?.map((item:Protocols) => {
        return item.id
      })

      // 如果匹配到的是H5产品，则走H5产品的申请、联登流程
      if (rqResult?.thirdpartyType === 1 && rqResult.id) {
        handleH5Product(rqResult.id, 0, protocolIds)
        return
      }
      setBtnLoading(true)
      // 前端日志上报
      const reportParam = `uid:${userInfo?.uid},adChannelCode:${details?.adChannelCode || apiChannelCode},event:授权点击`
      indexApi.reportLog(reportParam)
      // 百度埋点-授权
      // if (channelValue?.includes('bdVid')) {
      //   window._agl && window._agl.push(['track', ['success', { t: 5 }]])
      // }
      // 前端神策埋点 - 用户点击授权
      sensorsVisitAuthorizePage(SensorsType.click_authorize, rqResult, checkInstitution)
      // 信息授权
      const res = await indexApi.confirmAuthorize({
        productId: rqResult?.id,
        protocolIds,
        agree: true,
        adChannelCode: details?.adChannelCode,
        isHuaWeiPhone: details?.isHuaWeiPhone,
        apiChannelCode,
        orderNum: authNum, //第几回授权
        serialNo: rqResult?.serialNo || serialNo,
        productIds: checkInstitution
      })
      setBtnLoading(false)
      handleAduitSuccess(res)
    } catch (error) {
      setBtnLoading(false)
    }
  }

  // 处理H5产品逻辑
  const handleH5Product = async(productId:number, entranceType:EntranceType, protocolIds:(string | null)[] | undefined) => {
    // todo 这里authH5Product和applyFor在APIfox暂无接口文档
    // 申请产品
    let applyProductRes = await indexApi.applyFor({
      productId,
      entranceType,
      appH5: 2
    })
    // 判断是否需要联登
    if (applyProductRes?.unionLoginFlag === 1) {
      try {
        const authH5ProductRes = await indexApi.authH5Product({
          productId,
          protocolIds: `${protocolIds}${''}`,
          agree: true,
          entranceType,
          appH5: 2
        })
        if (authH5ProductRes) {
          window.location.href = authH5ProductRes
        } else {
          navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify({ rePageShowType: 0 }))}`)
        }
      } catch (error) {
        navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify({ rePageShowType: 0 }))}`)
      }
    } else {
      window.location.href = applyProductRes.thirdpartyTargetUrl
    }
  }

  // 修改页面url
  const reFreshPageUrl = (newRqResult:ProductNextMatchDTO, tempAuthNum: number | null) => {
    const pageUrl = window.location.origin + window.location.pathname
    let urlOptions
    // 如果是API采量授权方式
    if (apiChannelCode) {
      let modifiedUrl = `${pageUrl}?apiChannelCode=${apiChannelCode}&jumpUrl=${encodeURIComponent(JSON.stringify(jumpUrl))}&authNum=${tempAuthNum}&rqResult=${encodeURIComponent(JSON.stringify(newRqResult))}&serialNo=${serialNo}`
      window.history.replaceState(null, 'null', modifiedUrl)
    } else {
      urlOptions = {
        ...details,
        rqResult: newRqResult,
      }

      window.history.replaceState(null, 'null', `${pageUrl}?options=${encodeURIComponent(JSON.stringify(urlOptions))}&authNum=${tempAuthNum}`)
    }
  }
  // 这里阐述一下，如果撞库通过则有以下三种情况：
  // 撞库通过并且携带jumpUrl，则跳转到jumpUrl指定的页面
  // 如果是APi转H5，则直接跳转第三方链接
  // 如果授权失败，但是返回了下一个产品就重新授权，这里面针对匹配回调类型thirdpartyType === 5进行了单独处理，否则重新匹配进入此页面
  // 如果以上三种情况都不属于，则直接跳转到成功下载页面，成功页面也会进行判断，如果是分期乐渠道，是没有下载按钮的
  const handleAduitSuccess = async(res: ExpandAuthResponese) => {
    // 正常授权且有结果(撞库通过)
    if (res && res.applyStatus !== 2) {
      // 如果url上有携带跳转地址jumpUrl，则跳转
      if (jumpUrl) {
        res.jumpUrl = jumpUrl
        navigate(`/fill-information/success-transfer?options=${encodeURIComponent(JSON.stringify(res))}`)
        return
      }
      // 如果是API转H5类型，则直接跳转第三方链接
      if ((res.thirdpartyType === ThirdpartyType.ApiFormH5Redirect || res.thirdpartyType === ThirdpartyType.JointLogin) && res.thirdpartyTargetUrl) {
        await indexApi.apiH5JumpUrlSuccessFlag({
          uid: userInfo?.uid,
          productId: rqResult?.id,
          productName: rqResult?.productName,
          thirdpartyTargetUrl: res.thirdpartyTargetUrl
        })
        window.location.href = res.thirdpartyTargetUrl
        return
      }

      // 如果授权失败，但是有返回下一个产品，就重新授权
      if (res.applyStatus === 4 && res.productNextMatchDTO) {
        // productNextMatchDTO没有返回serialNo，需要保存
        if (rqResult?.serialNo) {
          res.productNextMatchDTO.serialNo = rqResult?.serialNo
        }
        // 判断是否匹配回调类型(如：惠逸花、惠逸花A)
        // if (res.productNextMatchDTO.thirdpartyType === 5) {
        //   toHuiYiHuaPage(res.productNextMatchDTO)
        //   return
        // }
        // 如果是其它产品，则更新本页面信息
        setRqResult(res.productNextMatchDTO)
        setAuthNum(res.productNextMatchDTO.orderNum)
        Toast.show('申请名额已满，您的匹配机构已更新，请继续操作')
        reFreshPageUrl(res.productNextMatchDTO, res.productNextMatchDTO.orderNum)
        return
      }

      navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify(res))}&isMultiProduct=${checkInstitution.length > 0}`)
      return
    }

    // 结果页
    // 走这里说明是重复授权或者授权失败，重复授权也需要判断res
    const options = res ? res : { rePageShowType: details?.adChannelCode === '9o0w2B' && details?.isHuaWeiPhone ? 2 : 0 }
    navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify(options))}&isMultiProduct=${checkInstitution.length > 0}`)
  }

  const handelSubmitData = () => {
    setChecked(true)
    submitApplication(false)
  }

  // 弹起协议相关列表
  const openProtocolsPopup = () => {
    setProtocolIdsVisible(true)
  }


  // 倒计时
  const [countdownTime, setCountdownTime] = useState<number>(5)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  // 倒计时
  useEffect(() => {
    if (isMobile) {
      if (countdownTime > 0) {
        timer.current = setTimeout(() => {
          setCountdownTime(countdownTime - 1)
        }, 1000)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTime])

  const checkOtherProtocols = (item: InstitutionProtocol) => {
    if (item.id) {
      openOtherView(`/info-audit-agreement?isShowHeader=true&agreementId=${item.id}&title=${item.name}&productId=${rqResult?.id}`)
    } else if (item.url) {
      window.location.href = item.url
    }
  }

  // 静态麦吉优共享协议
  const mjyUserAgreement = async() => {
    let userName
    let fillInfo = localStorage.getItem('fillInformation')
    if (fillInfo) {
      userName = JSON.parse(fillInfo).realName
    }
    let userLoginInfo = await indexApiInfo.getLoginUserInfo()

    const htmlContent = `
      <p style="text-align: center;"><strong>《用户个人信息共享授权协议》</strong></p>
      <p><span style="font-family: 宋体;">《用户个人信息共享授权协议》 (以下简称“本协议”)是由本公司作为个人信息处理者】</span></p>
      <p><span style="font-family: 宋体;">(以下称“我们”)与您所订立的有效合约</span></p>
      <p><strong>【审慎阅读】当您通过本页面使用我们提供的</strong></p>
      <p><strong>【第三方产品推荐】服务时，您的个人信息会进行共享。为了您的合法权益，请您在使用本服务前务必仔细阅读本协议的全部内容(特别是以粗体/下划线标注的内容)。如果您不同意本协议内容，或无法准确理解本协议任何条款的含义，请不要进行确定及后续操作【签约动作】您同意本协议以数据电文形式订立。如您通过网络页面点击“同意或“注册”或“申请”或“接受用户授权协议”(以网页显示为准)，即视为您已阅读理解本协议的全部内容并同意我们出于相关页面所述目的，将您的个人信息提供给为您提供【用户联登撞库】服务的其他个人信息处理者，本协议即生效</strong></p>
      <p><strong>【共享信息】您授权我们将您的</strong></p>
      <p><span style="font-family: 宋体;">手机号：${userLoginInfo.data.mobile}</span></p>
      <p><strong>信息通过【加密通道/文件/接口方式】传输给第三方为您提供</strong><span style="font-family: 宋体;">【用户联登撞库】</span><strong>服务</strong><span style="font-family: 宋体;">。我们会要求该第三方依法使用您的上述信息。</span><strong>如果您不希望向第三方提供上述信息，请勿点击授权。</strong></p>
      <p><strong>您理解，第三方服务由相应的第三方提供并就以上服务及处理您个人信息的安全性独立承担责任。请您在授权同意前，仔细阅读并理解第三方的服务协议与隐私政策。因该第三方服务或其处理您的个人信息产生的纠纷，或第三方服务违反相关法律法规或者协议约定，或您在使用第三方服务过程中遭受了损失，请您与第三方协商解决。</strong></p>
      <p><strong>【争议解决】凡因本协议引起的任何与之有关的争议，首先应由该等争议的相关当事方通过友好协商解决，如无法友好协商解决的，任何一方均可向广州海珠区人民法院提起诉讼。</strong></p>
      <p><span style="font-family: 宋体;">用户姓名:【${userName || ''}】</span></p>
      <p><span style="font-family: 宋体;">用户账号:【${userLoginInfo.data.uid}】</span></p>
      <p><span style="font-family: 宋体;">日期：【${moment().format('YYYY-MM-DD hh:mm:ss')}】</span></p>
    `
    return htmlContent
  }

  const checkFaProtocol = (protocol: InstitutionProtocol) => {
    if (protocol.protocolList && protocol.protocolList?.length > 0) {
      if (protocol.name === checkProtocol) {
        setCheckProtocol('')
      } else {
        setCheckProtocol(protocol.name)
      }
    } else {
      checkOtherProtocols(protocol)
    }
  }

  // 当多产品分发的产品勾选改变
  const onCheckedChange = (id: number, value: boolean) => {
    // 创建状态的副本
    let newCheckInstitution = [...checkInstitution]
    const index = newCheckInstitution.findIndex(item => item === id)

    if (value) {
      // 如果 value 为 true 且 id 不在数组中，则将 id 添加到数组中
      newCheckInstitution.push(id)
    } else {
      // 如果 value 为 false 且 id 在数组中，则将 id 从数组中移除
      newCheckInstitution.splice(index, 1)
    }
    // 更新状态
    setCheckInstitution(newCheckInstitution)
  }

  const getTextTips = () => {
    switch (true) {
      case channelValue?.includes('hbjf'):
      case channelValue?.includes('mjy'):
        return <img className={styles.textTips} alt="youqianqianbao-prompt" src={require('@imgs/information/text-tips2.png')} />
      case channelValue?.includes('jurongyi'):
        return <img className={styles.textTips} alt="youqianqianbao-prompt" src={require('@imgs/jufu/haier-prompt.png')} />
      case channelValue?.includes('juhaojie'):
        return <img className={styles.textTips} alt="youqianqianbao-prompt" src={require('@imgs/jufu/haier-prompt.png')} />
      case channelValue?.includes('jufu'):
        return <img className={styles.textTips} alt="youqianqianbao-prompt" src={require('@imgs/jufu/prompt.png')} />
      default:
        return <img className={styles.textTips} alt="youqianqianbao-prompt" src={require('@imgs/information/text-tips.png')} />
    }
  }

  return (
    <section className={styles.authorizePage}>
      <Header />
      <div>
        {
          rqResult?.thirdpartyType && rqResult?.thirdpartyType !== 5 ? <>
            {
              apiChannelCode ? <>
                <ApiToH5Skin
                  rqResult={rqResult}
                  agreementContent={agreementContent}
                  youqianlaiProtocolName={youqianlaiProtocol ? youqianlaiProtocol.name : ''}
                  isSpecialChannel={isSpecialChannel}
                />
              </> : <>
                {
                  rqResult?.thirdpartyType && <div>
                    <div className={styles.headerBox}>
                      {
                        getTextTips()
                      }
                    </div>
                    <div className={styles.mainContent}>
                      <div className={styles.limitBox}>
                        <div className={styles.limitBoxTitle}>最高可借额度（元）</div>
                        <div className={styles.limitNum}>{ details?.applyLimit && details.applyLimit >= 3 ? '100,000' : '50,000'}</div>
                        <div className={styles.splitLine}></div>
                        <div className={styles.limitBoxFooter}>
                          <div className={styles.footerItem}>
                            <img className={styles.footerItemIcon} alt="有钱钱包-利率" src={require('@imgs/information/ic_safe-1.png')} />
                            <span>年化利率7.2%起</span>
                          </div>
                          <div className={styles.footerItem}>
                            <img className={styles.footerItemIcon} alt="有钱钱包-介绍" src={require('@imgs/information/ic_safe-2.png')} />
                            <span>随时可还款</span>
                          </div>
                          <div className={styles.footerItem}>
                            <img className={styles.footerItemIcon} alt="有钱钱包-额度"src={require('@imgs/information/ic_safe-3.png')} />
                            <span>额度灵活</span>
                          </div>
                        </div>
                      </div>
                      <InstitutionList rqResult={rqResult} onCheckedChange={onCheckedChange} checkInstitution={checkInstitution}></InstitutionList>
                      <div className={styles.protocolBox}>
                        <div className={styles.specialTips}>
                          特别提示：我们会根据您的资质，为您匹配专业的贷款咨询服务机构，请您查阅《个人信息共享授权协议》
                        </div>
                        <div className={styles.protocolBoxContent}>
                          <div className={styles.protocolBoxTitle}>{youqianlaiProtocol?.name}</div>
                          <div dangerouslySetInnerHTML={{ __html: agreementContent }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </>
            }
            <div className={styles.footerBox}>
              <div className={styles.footerText}>
                {
                  checked ? <>
                    {
                      channelValue?.includes('jufu')
                        ? <img onClick={checkedTaggle} alt="checked" className={styles.checkedIcon} src={require('@imgs/jufu/checked.png')} />
                        : <img onClick={checkedTaggle} alt="checked" className={styles.checkedIcon} src={require('@imgs/information/checked.png')} />
                    }
                  </> : <>
                    <div className={classNames(styles.checked)} onClick={checkedTaggle}></div>
                  </>
                }
                <div>
                  <span onClick={checkedTaggle}>同意并签署</span>
                  {/* 聚客融渠道不需要展示个人协议，需要展示通用授权协议和非大学生承诺函 */}
                  {/* 如果是h5类型，则展示联登协议，联登协议是后台返回 */}
                  {
                    rqResult.thirdpartyType !== 1 && channelValue?.includes('jufu')
                      ? <>
                        <span
                          className={styles.footerTextRed}
                          onClick={() => {
                            openOtherView(`/jkr-general-agreement?isShowHeader=true&useInfo=${JSON.stringify(userInfo)}&company=${rqResult.productCompany || rqResult.yqlWholeProcessBumpVO?.partnerCompanyName}`)
                          }}
                        >
                《通用授权协议》
                        </span>
                        <span
                          className={styles.footerTextRed}
                          onClick={() => {
                            openOtherView(`/jkr-student-agreement?isShowHeader=true&useInfo=${JSON.stringify(userInfo)}`)
                          }}
                        >
                《非大学生承诺函》
                        </span>
                      </>
                      : <span
                        className={styles.footerTextRed}
                        onClick={() => {
                          openOtherView(`/info-audit-agreement?isShowHeader=true&agreementId=${youqianlaiProtocol?.id}&title=${youqianlaiProtocol?.name}&productId=${rqResult.id}&protocolType=${channelValue?.includes('mjy') || channelValue?.includes('hbjf') ? 1 : 0}`)
                        }}
                      >
                《{youqianlaiProtocol?.name}》
                      </span>
                  }

                  {/* 聚客融-快手需要屏蔽掉匹配机构的协议 */}
                  {
                    otherProtocols.length > 0 && <span
                      className={styles.footerTextRed}
                      onClick={() => {
                        openProtocolsPopup()
                      }}
                    >
                    《机构相关协议》
                    </span>
                  }
                </div>
              </div>
              <div className={styles.btnBox}>
                <Button block loading={btnLoading} loadingText="请等待机构申请结果" className={styles.btn} shape="rounded" color="primary" onClick={() => submitApplication(true)}>
                  { `立即领取额度${countdownTime ? `（${countdownTime}s）` : ''}`}
                </Button>
              </div>
              <div className={styles.paddingBox}></div>
            </div>
          </> : <>
            <div id="root">
              <div id="loading-box">
                <div className={styles.ballClipRotateMultiple}>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </>
        }
      </div>

      <Popup
        visible={protocolIdsVisible}
        showCloseButton={true}
        bodyStyle={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          minHeight: '40vh',
        }}
        onMaskClick={() => {
          setProtocolIdsVisible(false)
        }}
        onClose={() => {
          setProtocolIdsVisible(false)
        }}
      >
        <div className={styles.protcolPopup}>
          <div className={styles.popupTitle}>协议列表</div>
          <div className={styles.protocolIdsBox}>
            {
              rqResult?.mayAuthProductList && rqResult?.mayAuthProductList?.length > 0
              // 如果是多产品分发，则显示树状协议列表
                ? institutionProtocol?.map(protocol => {
                  return <div key={protocol.name}>
                    <div
                      className={styles.protocolIdItem}
                      onClick={() => checkFaProtocol(protocol)}
                    >
                      <div>{ `《${protocol.name}》` }</div>
                      <img className={classNames([styles.arrowBlack, checkProtocol === protocol.name && styles.chooseArrow])} alt="arrow-black" src={require('@imgs/information/arrow-black.png')} />
                    </div>
                    {
                      checkProtocol === protocol.name ? protocol?.protocolList?.map(child => {
                        return <div key={child.name} onClick={() => checkOtherProtocols(child)} className={styles.protocolChild}>
                          <span>{child.name}</span>
                          <RightOutline />
                        </div>
                      }) : <></>
                    }
                  </div>
                })
                : otherProtocols?.map(item => {
                  return (
                    <div
                      className={styles.protocolIdItem}
                      onClick={() => checkOtherProtocols(item)}
                      key={item.name}
                    >
                      <div>{ `${item.name}` }</div>
                      <img className={styles.arrowBlack} alt="arrow-block" src={require('@imgs/information/arrow-black.png')} />
                    </div>
                  )
                })
            }
          </div>
        </div>
      </Popup>

      {/* 同意协议弹窗 */}
      <ProtocolIdsConfirm
        ref={ProtocolIdsConfirmModalRef}
        rqResult={rqResult}
        openProtocolsPopup={() => openProtocolsPopup()}
        submit={() => handelSubmitData()}
      />

    </section>
  )
}

export default Authorize
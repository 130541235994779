/* eslint-disable no-shadow */
// 接口返回全局状态码
export const RESPONSE_CODE = {
  SUCCESS: 200, // 接口调用成功
  TOKEN_OVERDUE: 401, // token 失效，登录过期
  NO_PERMISSION: 403, // 无访问资源权限
  TOKEN_ERROR: 1005, // token 无效
  OTHER_LOGIN: 1006, // 账号被其他人登录
  ACCOUNT_FORBIDDEN: 1007, // 账号被禁用
  LOGIN_PSW_NAME_ERROR: 1000, // 登录名或密码错误
}

export const ENTRANCE_TYPE = {
  UNKNOW: 0,

  /** 首页中插 */
  HOME_PAGE_INSERTION: 1,

  /** 首页弹窗 */
  HOME_PAGE_POPUP: 2,

  /** 开屏 */

  THE_TAIL: 3,

  /** 顶部推荐 */

  TOP_RECOMMENDATIONS: 4,

  /** 首页列表 */

  HOME_PAGE_LIST: 5,

  /** 产品列表 */

  LIST_OF_PRODUCTS: 6,

  /** 随机推荐 */

  RANDOM_RECOMMENDATION: 7
} as const

/**
 * 公司主体及备案号
 */
export const CompanyType = {

  /** 虫洞跳跃信息 */

  YQQB_COMPANY: {
    company: '广州虫洞跳跃信息科技有限公司',
    recordNumber: '粤ICP备2022120631号'
  },

  /** 中安信业信息 */

  ZAXY_COMPANY: {
    company: '深圳市中安信业小额贷款有限公司',
    recordNumber: '粤ICP备16075822号'
  },

  /** 聚富小贷信息 */

  JFXD_COMPANY: {
    company: '广州市聚富互联网小额贷款有限公司',
    recordNumber: '粤ICP备17137794号'
  },

  /** 魅塔信息 */
  META_COMPANY: {
    company: '深圳市中安信业小额贷款有限公司',
    recordNumber: '粤ICP备16075822号-1'
  },

  /** 聚富小贷信息 */

  JKR_COMPANY: {
    company: '深圳聚客融科技有限公司',
    recordNumber: '粤ICP备2023009975号'
  },
}

/**
 * 请求拦截器携带埋点参数
 */
export enum InterceptorParams {

  /** 快手 */
  KS_PARAMS = 2,

  /** 百度 */
  BD_PARAMS = 3,

  /** Soul */
  SOUL_PARAMS = 4
}

/* eslint-disable no-nested-ternary */
/* eslint-disable no-magic-numbers */
import React, { useMemo } from 'react'
import styles from './normal-skin.module.scss'
import classNames from 'classnames'
import { Button } from 'antd-mobile'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import Icon from '@/components/icon/icon'
interface Props {
  productName?: '', // 产品名字
  applyStatus?: number, // hold单状态
  rePageShowType?: number,
  thirdpartyApiType?: number,
  thirdpartyType?: number,
  countdownTime: number,
  onChange: () => void, // 改变事件
  isMultiProduct:boolean
}

const NormalSkin: React.FC<Props> = props => {
  const { channelValue } = useSelector((state: RootState) => state.channel)
  const { productName, rePageShowType, countdownTime, onChange, isMultiProduct } = props

  // 文字logo
  const youqianLogoText = useMemo(() => {
    switch (true) {
      case channelValue?.includes('juhaojie'):
        return <Icon type="jufu-haier-text-logo" className={styles.youqianTextLogoSvg}></Icon>
      case channelValue?.includes('jurongyi'):
        return <Icon type="jufu-jurongyi-text-logo" className={styles.youqianTextLogoSvg}></Icon>
      case channelValue?.includes('jufu'):
        return <img className={styles.youqianLogoText} src={require('@imgs/jufu/title.png')} alt="youqian-text" />
      default:
        return <img className={styles.youqianLogoText} src={require('@imgs/youqianqianbao-text.png')} alt="youqian-text" />
    }
  }, [channelValue])

  // 结果页logo
  const youqianLogo = useMemo(() => {
    switch (true) {
      case channelValue?.includes('juhaojie'):
        return <img className={styles.logo} style={{ borderRadius: '5px' }} src={require('@imgs/jufu/jufuLogo.jpg')} alt="logo" />
      case channelValue?.includes('jurongyi'):
        return <img className={styles.logo} style={{ borderRadius: '5px' }} src={require('@imgs/jufu/jurongyiLogo.png')} alt="logo" />
      default:
        return <img className={styles.logo} src={require('@imgs/youqianqianbao-logo.png')} alt="logo" />
    }
  }, [channelValue])

  return (
    <div className={styles.fullPage}>
      <div className={styles.main} style={{
        backgroundImage:
        channelValue?.includes('jufu') ? `url(${require('@imgs/jufu/result-backgroud.png')})`
          : `url(${require('@imgs/ios-skin/head-bg.png')})`
      }}>
        {
          channelValue?.includes('mjy') || channelValue?.includes('hbjf') ? <></> : <div className={styles.logoBox}>
            {youqianLogo}
            <div className={styles.logoBoxRight}>
              {youqianLogoText}
              <img className={styles.logoText2} src={require('@imgs/register/new-theme/logo-subtitle.png')} alt="正规品牌 真实可信" />
            </div>
          </div>
        }
        {
          productName ? <>
            <div className={styles.applyResultBox}>
              <img className={styles.applyResultBoxIcon} src={require('@imgs/ios-skin/success.png')} alt="success" />
              <div className={styles.applyResultBoxRight}>
                <div className={styles.applyResultBoxText1}>恭喜, <span className={styles.active}>{!isMultiProduct && productName}</span>申请成功！</div>
                <div className={styles.applyResultBoxText2}>请留意审核人员来电，完成信息审核</div>
              </div>
            </div>
            <div className={classNames(styles.containers)}>
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/pass.png')} alt="pass" />
                <div className={styles.containerItemSuccessText}>预审通过</div>
              </div>
              <img className={styles.line} src={require('@imgs/form-result/line.png')} alt="line" />
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/phone.png')} alt="phone" />
                <div className={styles.containerItemFailText}>请留意审核人员来电</div>
              </div>
            </div>
          </> : <>
            <div className={styles.applyResultBox}>
              <img className={styles.applyResultBoxIcon} src={require('@imgs/ios-skin/success.png')} alt="success" />
              <div className={styles.applyResultBoxRight}>
                <div className={styles.applyResultBoxText1}><span className={styles.active}>您已预审通过</span></div>
                <div className={classNames(styles.applyResultBoxText2, styles.smallText)}>抱歉，当前申请名额已满，请下载APP查看</div>
              </div>
            </div>
            <div className={classNames(styles.containers)}>
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/pass.png')} alt="pass" />
                <div className={styles.containerItemSuccessText}>预审通过</div>
              </div>
              <img className={styles.line} src={require('@imgs/form-result/line.png')} alt="line" />
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/phone.png')} alt="phone" />
                <div className={styles.containerItemFailText}>下载APP领取额度</div>
              </div>
            </div>
          </>
        }
      </div>
      {
        rePageShowType !== 2 && rePageShowType !== 1 && <div className={styles.btnBox}>
          <div className={styles.downLoadTips}>快前往APP查看更多可借产品</div>
          <Button block className={styles.btn} shape="rounded" color="primary" onClick={onChange}>{`点击下载APP${countdownTime > 0 ? `（${countdownTime}）` : ''}`}</Button>
        </div>
      }
    </div>
  )
}

export default NormalSkin

// 组件注释
import React, { useEffect, useState, useImperativeHandle } from 'react'
import styles from './user-info-form.module.scss'
import { Form, Input, CascadePickerView, Toast, Button, Popup, Divider } from 'antd-mobile'
import { validateIDNumber } from '@bihu/common-js'
import classNames from 'classnames'
import cityDate from '@/assets/js/city'
import type { PickerValue, PickerValueExtend } from 'antd-mobile/es/components/picker'
import { FormItem } from '../../fill-information-three'
import Icon from '@/components/icon/icon'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

export interface OptionType{
  label: string,
  value: string,
  num:number
}

interface Props {
  // 当前步骤
  step: number,
  // 传入的资产信息列表
  formItemList: Array<FormItem>
  // 修改
  onChange: (obj: OptionType, formItem: FormItem, index: number) => void,
  onChangeFormValue: (type: string, val: string) => void,
  // 名字
  realName: string, //真实姓名
  identity: string, //身份证
  cityShow: string, // 城市input展示字段
  cityValue: (string | null)[], //城市编码
  cityConfirm: (value: PickerValue[], extend: PickerValueExtend) => void, //修改城市
  confirm: () => void // 表单2身份验证成功
  isSubimtWaiting: number,
  waitingProgress:number
}

export interface UserInfoFormComponentHandles {
  btnUpdate: () => void
}

const UserInfoFormComponent = React.forwardRef<UserInfoFormComponentHandles, Props>(
  ({ step, formItemList, onChange, onChangeFormValue, realName, identity, cityShow, cityValue, cityConfirm, confirm, isSubimtWaiting, waitingProgress }, ref) => {
    const { channelValue } = useSelector((state: RootState) => state.channel)
    const [collapse, setcollapse] = useState<null | number>(step) // 当前展开折叠索引
    const [cityVisible, setCityVisible] = useState(false) // 城市
    const [cascadePickerValue, setCascadePickerValue] = useState<PickerValue[]>(cityValue)
    const [cascadePickerExtend, setCascadePickerExtend] = useState<PickerValueExtend>({
      columns: [],
      items: []
    }) // 城市选择的
    const [form] = Form.useForm() // 表单
    // 表单校验失败控件
    const [formError, setFormError] = useState(false)

    // 身份证验证
    const checkIdentity = (_: unknown, value: string) => {
      if (value) {
        if (!validateIDNumber(value)) {
          return Promise.reject(new Error('身份证号错误，请重新输入'))
        }
        return Promise.resolve()
      }
      return Promise.reject(new Error('请输入身份证号'))
    }

    // 居住城市验证
    const checkLivingCity = (_: unknown, value: string) => {
      if (!cityShow) {
        return Promise.reject(new Error('请选择居住城市'))
      }
      return Promise.resolve()
    }
    useEffect(() => {
      const openLevels = formItemList.reduce((count, item) => {
        // 检查当前项的 value 是否有值且不为空
        if (item.value && item.value !== '') {
          return count + 1 // 如果有值，打开级数加1
        }
        return count // 否则保持不变
      }, 0)
      setcollapse(openLevels)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step])

    // 选择展开折叠索引
    const chooseCollapse = (index: number) => {
      setcollapse(collapse === index ? null : index)
    }

    // 选择信息完成
    const chooseInfoItem = (option: OptionType, item: FormItem, index: number) => {
      setcollapse(typeof collapse === 'number' ? collapse + 1 : 0)
      onChange(option, item, index)
    }

    useEffect(() => {
      // 当 realName 或 identity 改变时，更新表单字段
      form.setFieldsValue({
        realName,
        identity
      })
    }, [realName, identity, form])

    // 提交表单校验
    const btnUpdate = () => {
      form.validateFields().then(obj => {
        confirm()
      })
        .catch(error => {
          if (error) {
            let filed = Object?.keys(error.errorFields[0])[1]
            Toast.show(error.errorFields[0][filed][0])
          }
          const dom = document.getElementById('app')
          if (dom) {
            dom.scrollTo(0, 0)
          }
        })
    }

    // 提交表单校验失败
    const setFormErrorAPI = () => {
      setFormError(true)
    }

    useEffect(() => {
      setCascadePickerValue(cityValue)
    }, [cityValue])

    // 向父组件暴露方法
    useImperativeHandle(ref, () => ({
      btnUpdate,
      setFormErrorAPI
    }))
    return (
      <div className={styles.form}>
        <Form
          form={form}
          layout="horizontal"
        >
          <div className={styles.userInfoItem}>
            <div className={styles.userBaserInfo}>
              <div className={styles.userBaserInfoTitleIconBox}>
                <img className={styles.moneyIcon} src={require('@/assets/imgs/fill-infomation-three/money.png')} alt="" />
                <span>完成实名认证，领取</span>
                <span className={styles.ownNum}>个人专属额度</span>
              </div>
              <div className={styles.warningText}>* 仅用于核验身份 您的资料将被加密保护</div>
              <Divider />
              <Form.Item
                label="姓名"
                name="realName"
                rules={[
                  {
                    validateTrigger: 'onblur',
                    required: true
                  },
                ]}
              >
                <Input maxLength={4} value={realName} placeholder="请输入姓名" onBlur={val => {
                  onChangeFormValue('realName', val.target.value)
                }} />
              </Form.Item>
              <Divider />
              <Form.Item
                label="身份证号"
                name="identity"
                rules={[
                  {
                    validateTrigger: 'onBlur',
                    validator: checkIdentity
                  }
                ]}
              >
                <Input
                  placeholder="请输入身份证号"
                  maxLength={18}
                  value={identity}
                  onBlur={val => {
                    onChangeFormValue('identity', val.target.value)
                  }} />
              </Form.Item>
            </div>
          </div>
          <div className={styles.userInfoItem}>
            <div className={styles.usercityInfo}>
              <Form.Item
                label="当前城市"
                name="livingCity"
                trigger="onConfirm"
                className={styles.city}
                rules={[
                  {
                    validator: checkLivingCity
                  }
                ]}
                onClick={e => {
                  setCityVisible(true)
                }}>
                <>
                  <Input placeholder="请选择城市" readOnly value={cityShow} />
                  <Popup
                    visible={cityVisible}
                    onMaskClick={() => {
                      setCityVisible(false)
                    }}
                    bodyStyle={{
                      minHeight: '30vh',
                    }}
                  >
                    <div className={styles.btnList}>
                      <Button style={{
                        color: '#666666',
                        fontSize: '16px'
                      }} fill="none"
                      onClick={() => {
                        setCityVisible(false)
                      }}>取消</Button>
                      <Button
                        style={{
                          color: '#FF041D',
                          fontSize: '16px'
                        }}
                        fill="none"
                        onClick={() => {
                          cityConfirm(cascadePickerValue, cascadePickerExtend)
                          setCityVisible(false)
                        }}
                      >确认</Button>
                    </div>
                    <p className={styles.noticle}>温馨提示：机构不支持跨城市申请，请选择您居住/工作所在城市</p>
                    <CascadePickerView
                      options={cityDate}
                      value={cascadePickerValue}
                      onChange={(e, v) => {
                        setCascadePickerExtend(v)
                        setCascadePickerValue(e)
                      }}
                    />
                  </Popup>
                </>
              </Form.Item>
              <Divider />
              <div className={styles.cityWarngingText}>
                <span className={styles.warning}>金融机构不支持跨区域办理</span>
                <span>请选择当前长期居住城市</span>
              </div>
            </div>
          </div>
        </Form>
        <div className={styles.userInfoItem} style={{ marginBottom: '0px' }}>
          <div className={styles.userFormInfo}>
            <div className={styles.assetInfomation}>
              <img className={styles.userFormIcon} src={require('@/assets/imgs/fill-infomation-three/security.png')} alt="" />
              <span>补充资产信息 平均提高</span>
              <span className={styles.userFormText}>90%通过率</span>
            </div>
            <Divider />
            {
              <div className={styles.formList}>
                {
                  formItemList.map((item, index) => {
                    return item.key !== 'lastPage' && <React.Fragment key={item?.key + index}>
                      {
                        <div className={styles.formItem} key={item?.key + index}>
                          <div className={styles.formItemTitle} onClick={() => chooseCollapse(index)}>
                            <span className={styles.leftItemBox}>
                              {item.name}
                            </span>
                            <span className={styles.flex}>
                              { item.value
                                ? <span className={styles.chooseLabel}>{ item.options.find((option:OptionType) => option.value === item.value)?.label }</span>
                                : <span className={styles.placeChoose} style={{ color: formError ? '#F63E48' : '#BFBFBF' }}>{ index === 0 && channelValue?.includes('meta') ? '增加' : '请选择'}{ item.name}</span>
                              }
                              <img style={{
                                transform: `rotate(${collapse === index ? 90 : 0}deg)`,
                              }} className={styles.arrow} src={require('@imgs/form-result/right.png')} alt="借款金额" />
                            </span>
                          </div>
                          {
                            collapse === index && <div className={styles.formItemContent}>
                              {
                                item.options.map((option:OptionType) => (
                                  <div
                                    key={option.value + option.num}
                                    className={classNames(styles.formItem, option.value === item.value && styles.activeItem)}
                                    onClick={() => chooseInfoItem(option, item, index)}>
                                    {option.label}
                                  </div>
                                ))
                              }
                            </div>
                          }
                        </div>
                      }
                      {
                        index !== formItemList.length - 2 && <Divider />
                      }
                    </React.Fragment>
                  })
                }
              </div>
            }
          </div>
        </div>
        <Button className={styles.btn} onClick={btnUpdate} block color="primary" size="large">
          {
            // eslint-disable-next-line no-nested-ternary
            isSubimtWaiting === 0 ? '马上申请' : isSubimtWaiting === 1 ? `正在匹配...${waitingProgress}%` : isSubimtWaiting === 2 ? '正在匹配...100%' : '匹配成功'
          }
        </Button>

      </div>
    )
  })

export default UserInfoFormComponent

/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/**
 * 申请额度
 */
export enum ApplyLimit {

  /** 0万~3万 */
  ZeroToThree = 1,

  /** 3万~5万 */
  ThreeToFive = 2,

  /** 5万~10万 */
  FiveToTen = 3,

  /** 10万~20万 */
  TenToTwenty = 4,

  /** 20万以上 */
  TwentyAndAbove = 5
}

/**
 * 重新匹配结果
 */
export enum RematchResult {

  /** 不需要重新匹配 */
  NoRematchNeeded = 1,

  /** 重新匹配不到结果 */
  NoResultAfterRematch = 2,

  /** 重新匹配到结果 */
  ResultAfterRematch = 3
}

/** 是否提交表单 */
export enum SubmitStatus {

  /** 0-未提交 */
  UnSubmit = 0,

  /** 1-提交表单未授权*/
  SubmitedUnauthorized = 1,

  /** 10-授权已分发 */
  Authroized = 10,
}

/** 申请状态 - 响应 */
export enum ApplyStatus {

  /** 产品销售火爆稍后再试*/
  HotSale = 0,

  /** 申请成功 */
  Success = 1,

  /** 已经申请过 */
  AlreadyApplied = 2,

  /** 资料不全 */
  IncompleteInfo = 3,

  /** 不符合条件 */
  NotEligible = 4,

  /** CRM产品推送失败 */
  CrmPushFailed = 5,

  /** API表单H5失败 */
  ApiH5Failed = 6,

  /** API表单H5撞库通过 -- 表示二段式进件，第一阶段成功 */
  ApiH5Pass = 7,

  /** hold单成功 */
  HoldSuccess = 8,
}

/**
 * 结果页展示类型
 */
export enum RePageShowType {

  /** 不做处理 */
  None = 0,

  /** 取消下载 */
  CancelDownload = 1,

  /** 引导公众号 */
  GuideToPublicAccount = 2
}

/**
 * 第三方合作方式
 */
export enum ThirdpartyType {

  /** H5跳转 */
  H5Redirect = 1,

  /** API接入 */
  ApiIntegration = 2,

  /** CRM接入 */
  CrmIntegration = 3,

  /** API表单H5跳转 */
  ApiFormH5Redirect = 4,

  /** 匹配回调类型 */
  MatchCallbackType = 5,

  /** SASSAPI */
  SassApi = 6,

  /** 全流程 */
  WholeProcess = 7,

  /** 大联登 */
  JointLogin = 8
}

/**
 * 利率单位
 */
export enum RateUnit {

  /** 日利率 */
  Daily = 1,

  /** 月利率 */
  Monthly = 2,

  /** 年利率 */
  Yearly = 3
}

/**
 * 撞库状态码
 */
export enum BumpStatus {

  /** 成功 */
  Success = 1,

  /** 用户已存在 */
  UserExists = 2,

  /** 其他原因失败 */
  OtherFailure = 3,

  /** 返回参数异常 */
  ParameterError = 999
}

/**
 * 人脸审核状态
 */
export enum AuditStatus {

  /** 0-人脸认证未开始 */
  FaceNoStart = 0,

  /** 1-人脸认证不通过 */
  FaceNoPass = 1,

  /** 2-认证通过（初审通过） */
  FcaePass = 2
}

/** 职业身份0 和 1 已被废弃，使用新的值 */
export enum Job {
  // /** 表示不是企业主 */
  // NotBusinessOwner = 0,
  // /** 表示是企业主 */
  // BusinessOwner = 1,
  /** 10->上班族 */
  Employee = 10,

  /** 20->自由职业者 */
  Other = 20,

  /** 30->企业主 */
  BusinessOwner = 30,
}

/** 用户资料状态枚举 */
export enum AssetSituation {

  /** 10-有房 */
  House = 10,

  /** 20-有车 */
  Car = 20,

  /** 30-有保单 */
  InsurancePolicy = 30,

  /** 40-有公积金 */
  ProvidentFund = 40,

  /** 50-有社保 */
  SocialSecurity = 50,
}

/** 房产 */
export enum HouseProperty {

  /** 0-无房产 */
  NoHouseAsset = 0,

  /** 有房产 */
  HasHouseAsset = 1,

  /** 有且接受抵押 */
  HasHouseAndAcceptMortgage = 2
}

/** 车产，0-无车产、1-有、2-有且接受抵押 */
export enum CarProperty {

  /** 0-无车产 */
  None = 0,

  /** 1-有车产 */
  HasCar = 1,

  /** 2-有且接受抵押 */
  HasCarWithMortgage = 2
}

/** 社保缴纳 */
export enum SocialSecurity {

  /** 0-无社保 */
  None = 0,

  /**  1-六个月以下 */
  LessThanSixMonths = 1,

  /** 2-六个月以上 */
  MoreThanSixMonths = 2
}

/** 公积金 */
export enum HousingFund {

  /** 无 */
  None = 0,

  /** 六个月以下 */
  UpToSixMonths = 1,

  /** 六个月以上 */
  SixMonthsAndAbove = 2,
}

/** 保险保单 */
export enum Insurance {

  /** 无保单 */
  None = 0,

  /** 缴纳不足1年 */
  LessThanOneYear = 1,

  /** 缴纳1年以上 */
  OneYearAbove = 2
}

/** 征信情况，0-无逾期、1-有逾期 */
export enum CreditInvestigation {

  /** 0-无逾期 */
  NoOverdue = 0,

  /** 1-有逾期 */
  HasOverdue = 1,
}

/** 工作年限 */
export enum WorkDuration {

  /** 10-6个月以下 */
  LessThanSixMonths = 10,

  /** 20-6-12个月 */
  SixToTwelveMonths = 20,

  /** 30-12个月以上 */
  MoreThanTwelveMonths = 30
}

/** 工资发放方式枚举 */
export enum PayoffForm {

  /** 1-银行转账 */
  BankTransfer = 1,

  /** 2-银行代发 */
  BankAgent = 2,

  /** 3-现金 */
  Cash = 3
}

/** 月收入 */
export enum MonthlyProfit {

  /** 1-5000以下 */
  Below5000 = 1,

  /** 2-500~1万 */
  Between5000And10000 = 2,

  /** 3-1万以上 */
  Above10000 = 3
}

/** 征信情况，10-花呗无逾期-11-花呗有逾期、20-白条无逾期、21-白条有逾期、30-信用卡无逾期、31-信用卡有逾期 */
/** H5不再使用，但是APP还在使用 */
export enum Credit {

  /** 10-花呗无逾期 */
  NoOverdueForHuaBei = 10,

  /** 11-花呗有逾期 */
  OverdueForHuaBei = 11,

  /** 20-白条无逾期 */
  NoOverdueForBaiTiao = 20,

  /** 21-白条有逾期 */
  OverdueForBaiTiao = 21,

  /** 30-信用卡无逾期 */
  CreditCardNoOverdue = 30,

  /** 31-信用卡有逾期 */
  CreditCardOverdue = 31,
}

/** 芝麻信用 */
export enum SesameCredit {

  /** 0-无芝麻分 */
  None = 0, //
  // /** 1-650分以下（弃用） */
  // BelowSixHundredFifty = 1,
  /** 2-650~700分 */
  SixHundredFiftyToSevenHundred = 2,

  /** 3-700分以上 */
  AboveSevenHundred = 3, //
  /**  4-600分以下 */
  BelowSixHundred = 4, //
  /** 5-600~650分 */
  SixHundredToSixHundredFifty = 5
}

/** 性别 */
export enum Gender {

  /** 1-男 */
  Male = 1,

  /** 2-女 */
  Female = 2
}



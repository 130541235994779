/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import type { RootState } from '@/store'
import { useSearchParams } from 'react-router-dom'
import sensors from 'sa-sdk-javascript'
import { Storage } from '@bihu/common-js'
import { useSensors } from './use-sensors'

export const useBuryingPoint = () => {
  const { adChannelCode, channelValue } = useSelector((state: RootState) => state.channel)
  const [search] = useSearchParams()

  // todo 暂时禁用
  // 百度埋点，前端埋点引入百度sdk
  const baiduBuryingPoint = () => {
    // 创建 script 元素
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = 'https://fxgate.baidu.com/angelia/fcagl.js?production=_f7L2XwGXjyszb4d1e2oxPybgD'

    // 将 script 插入到页面中
    const firstScriptTag = document.getElementsByTagName('script')[0]
    if (firstScriptTag && firstScriptTag.parentNode) {
      firstScriptTag.parentNode.insertBefore(script, firstScriptTag)
    }

    // 触发百度埋点的初始化代码
    window._agl = window._agl || []
    window._agl.push(['production', '_f7L2XwGXjyszb4d1e2oxPybgD'])
  }

  useEffect(() => {
    if (channelValue?.includes('bdVid') && adChannelCode && !Storage.get(`${adChannelCode}-BDburyingPoint`)) {
      let fullUrl = window.location.href
      Storage.set(`${adChannelCode}-BDburyingPoint`, fullUrl)
    }
    if (channelValue?.includes('ksBurying') && adChannelCode) {
      let ks = search.get('callback')
      ks && Storage.set(`${adChannelCode}-ksCallBack`, ks)
    }
    if (channelValue.includes('soulBurying') && adChannelCode) {
      let soul = search.get('callback')
      soul && Storage.set(`${adChannelCode}-soulCallBack`, soul)
    }
  }, [adChannelCode, channelValue])
}
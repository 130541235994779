/* eslint-disable camelcase */
// 成功页面
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './success-page.module.scss'
import indexApi from '@/apis'
import Header from '@/components/header/header'
import Icon from '@/components/icon/icon'
import { Button, Checkbox, Divider } from 'antd-mobile'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SensorsType } from '@/constants/sensorsBurying'
import sensors from 'sa-sdk-javascript'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import JointLoginPopup from '@/components/joint-login-popup/joint-login-popup'
import classNames from 'classnames'
import { useSensors } from '@/hooks/use-sensors'
import { EntranceType } from '@/types/apis'

interface Props { }

interface productItem {
    id: number;
    productName: string;
    productLogo: string;
    loansLimitMin: number;
    loansLimitMax: number;
    monthlyInterestRate: number;
    showDetailPage: number;
    showHomePageTop: number;
    showHomePageTopWeight: number;
    createdAt: number;
    unionLoginFlag: number;
    applyRate: number;
}

const SuccessPage: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { clickH5ProductSensors } = useSensors()
  const [search] = useSearchParams()
  const JointLoginModalRef: any = useRef()
  // 获取产品列表
  const [hotProductList, setHotProductList] = useState<productItem[]>([])
  const [showPage, setshowPage] = useState<'defalut' | 'product'>()
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const tagList = [
    {
      icon: 'tagicon1',
      label: '大额低息'
    },
    {
      icon: 'tagicon2',
      label: '灵活分期'
    },
    {
      icon: 'tagicon3',
      label: '随借随还'
    }
  ]
  const [checked1, setChecked1] = useState(true)
  const [checked2, setChecked2] = useState(true)
  // 获取产品列表
  useEffect(() => {
    getHomeProductList()
  }, [])


  // 获取产品列表
  const getHomeProductList = async() => {
    try {
      const res: any = await indexApi.homeProductList({ appH5: 2 })
      const tempProductList = [
        ...(res?.hotRecommends || []),
        ...(res?.jumboLoanRecommends || []),
        ...(res?.microfinanceRecommends || [])
      ]
      if (tempProductList.length > 0) {
        setshowPage('product')
        setHotProductList(tempProductList)
      } else {
        setshowPage('defalut')
      }
    } catch (err) {
      setshowPage('defalut')
    }
  }

  // 跳转页面
  const jumpToAgreementPage = (path: string) => {
    navigate(`/${path}-agreement?isShowHeader=true`)
  }

  // 生成随机数范围
  const randomNum = (minNum:number, maxNum:number) => {
    return parseInt((Math.random() * (maxNum - minNum + 1) + minNum).toString(), 10)
  }

  // 点击产品
  const applyProduct = async(item: any, entranceType: EntranceType) => {

    let res:any = await indexApi.applyFor({
      productId: item.id,
      entranceType,
      appH5: 2
    })

    // 前端神策埋点 - 用户点击后端 H5 产品
    clickH5ProductSensors(item.productName)

    if (res?.unionLoginFlag === 1) {
      const params = {
        productId: item.id,
        productName: item.productName,
        productLogo: item.productLogo,
        h5Protocols: res.h5Protocols,
        loansLimitMax: item.loansLimitMax,
        entranceType,
        appH5: 2
      }
      // 打开产品联登授权弹窗
      JointLoginModalRef.current?.init(params)
      return
    }

    window.location.href = res.thirdpartyTargetUrl
  }

  const youqianLogoText = useMemo(() => {
    switch (true) {
      case channelValue?.includes('juhaojie'):
        return <Icon type="jufu-haier-text-logo" className={styles.youqianTextLogoSvg}></Icon>
      case channelValue?.includes('jurongyi'):
        return <Icon type="jufu-jurongyi-text-logo" className={styles.youqianTextLogoSvg}></Icon>
      case channelValue?.includes('jufu'):
        return <img className={styles.youqianLogoText} src={require('@imgs/jufu/title.png')} alt="youqian-text" />
      default:
        return <img className={styles.youqianLogoText} src={require('@imgs/youqianqianbao-text.png')} alt="youqian-text" />
    }
  }, [channelValue])

  // 结果页logo
  const youqianLogo = useMemo(() => {
    switch (true) {
      case channelValue?.includes('juhaojie'):
        return <img className={styles.logo} style={{ borderRadius: '5px' }} src={require('@imgs/jufu/jufuLogo.jpg')} alt="logo" />
      case channelValue?.includes('jurongyi'):
        return <img className={styles.logo} style={{ borderRadius: '5px' }} src={require('@imgs/jufu/jurongyiLogo.png')} alt="logo" />
      default:
        return <img className={styles.logo} src={require('@imgs/youqianqianbao-logo.png')} alt="logo" />
    }
  }, [channelValue])

  return (
    <div className={classNames([styles.successPage, hotProductList.length <= 0 && styles.default])}
    >
      <Header />
      {
        showPage ? <>
          {
            showPage === 'product' && <div className={styles.monetizationBox}>
              {
                hotProductList.length >= 1
                  && <div className={classNames([styles.monetizationItem1, channelValue.includes('jufu') && styles.jufumonetizationItem1])}>
                    <div className={styles.monetizationItem1Header}>
                      <img className={styles.productLogo} src={hotProductList[0].productLogo} alt="" />
                      <div className={styles.productIntroduceTitle}>
                        <div className={styles.title}>
                            您好！<span >{ hotProductList[0].productName}</span> 出具的预估额度为：
                        </div>
                        <div className={styles.estimateNumBox}>
                          <div className={styles.estimateNum}>
                            <span className={styles.loansLimitMax}>{hotProductList[0].loansLimitMax}</span>
                            <span>（元）</span>
                          </div>
                          <span className={styles.warningText}>具体金额以实际审批为准</span>
                        </div>
                      </div>
                    </div>
                    <div className={styles.tagList}>
                      <div className={styles.tagItem}>
                        <Icon type={tagList[0].icon} size={16}></Icon>
                        <span>{ tagList[0].label}</span>
                      </div>
                      <Divider direction="vertical" />
                      <div className={styles.tagItem}>
                        <Icon type={tagList[1].icon} size={16}></Icon>
                        <span>{ tagList[1].label}</span>
                      </div>
                      <Divider direction="vertical" />
                      <div className={styles.tagItem}>
                        <Icon type={tagList[2].icon} size={16}></Icon>
                        <span>{ tagList[2].label}</span>
                      </div>
                    </div>
                    <Button className={styles.btn} color="primary" onClick={() => applyProduct(hotProductList[0], 6)}>确认额度</Button>
                    <div className={styles.checkedBox}>
                      <Checkbox
                        style={{
                          '--icon-size': '16px',
                          marginRight: '4px'
                        }}
                        checked={checked1} onChange={e => setChecked1(e)}>
                      </Checkbox>
                      <span>
                          阅读并同意
                        <span
                          className={styles.primary}
                          onClick={() => jumpToAgreementPage('register')}
                        >
                        《用户注册服务协议》
                        </span>
                          和
                        <span className={styles.primary} onClick={() => jumpToAgreementPage('privacy')}>《隐私政策》</span>
                      </span>
                    </div>
                  </div>
              }
              {
                hotProductList.length >= 2
        && <div className={styles.monetizationItem2}>
          <div className={styles.monetizetionItem2Header}>
            <div className={styles.introduceBox}>
              <img className={styles.productLogo} src={hotProductList[1].productLogo} alt="" />
              <div>
                <div className={styles.limitPrice}>¥{hotProductList[1].loansLimitMin}-{ hotProductList[1].loansLimitMax}</div>
                <div className={styles.productName}>具体金额以实际审批为准</div>
              </div>
            </div>
            <div className={styles.BtnBox}>
              <Button size="small" shape="rounded" className={styles.btnItem2} color="primary" onClick={() => applyProduct(hotProductList[1], 6)}>立即申请</Button>
              <div className={styles.peopleNum}>已有{randomNum(5000, 10000) }人申请</div>
            </div>
          </div>
          <div className={styles.checkedBox}>
            <Checkbox
              style={{
                '--icon-size': '14px',
                marginRight: '4px'
              }}
              checked={checked2} onChange={e => setChecked2(e)}>
            </Checkbox>
            <span>
              阅读并同意
              <span
                className={styles.primary}
                onClick={() => jumpToAgreementPage('register')}
              >
            《用户注册服务协议》
              </span>
              和
              <span className={styles.primary} onClick={() => jumpToAgreementPage('privacy')}>《隐私政策》</span>
            </span>
          </div>
        </div>
              }
              <div className={styles.moreMonetization}>
                {
                  hotProductList.length >= 3 && hotProductList.map((item, index) => {
                    if (index <= 1) {
                      return null
                    } else if (index === 2 || index === 3) {
                      return <div key={item.id} className={styles.moreMonetizationItem} onClick={() => applyProduct(item, 6)}>
                        <img src={item.productLogo} className={styles.productLogo} alt="" />
                        <div className={styles.productIntroduce}>
                          <div className={styles.moreMonetizationItemTitle}>{item.productName}</div>
                          <div>{ index === 2 ? '贷款额度高' : '还款利率低'}</div>
                        </div>
                      </div>
                    } else {
                      return null
                    }
                  })
                }
              </div>
              <div className={styles.noMoreProductBox}>
                <div className={styles.line1}></div>
                <div className={styles.noMoreText}>没有更多了</div>
                <div className={styles.line2}></div>
              </div>
            </div>
          }
          {
            showPage === 'defalut' && <div className={styles.defalutPageBox}>
              <div className={classNames([styles.main, channelValue.includes('jufu') && styles.jufudefalutPageBox])}>
                {
                  channelValue?.includes('mjy') || channelValue?.includes('hbjf') ? <></> : <div className={styles.logoBox}>
                    {youqianLogo}
                    <div className={styles.logoBoxRight}>
                      {youqianLogoText}

                      <img className={styles.logoText2} src={require('@imgs/register/new-theme/logo-subtitle.png')} alt="正规品牌 真实可信" />
                    </div>
                  </div>
                }

                <div className={styles.applyResultBox}>
                  <img className={styles.applyResultBoxIcon} src={require('@imgs/ios-skin/success.png')} alt="success" />
                  {
                  // applyStatus === 8 ?
                    <>
                      <div className={styles.applyResultBoxRight}>
                        <div className={styles.applyResultBoxText1}>恭喜, 匹配成功！</div>
                        <div className={styles.applyResultBoxText2}>审核人员将在<span className={styles.highlight}>工作时间来电</span>完成审核</div>
                      </div>
                    </>
                  //     : <>
                  //   <div className={styles.applyResultBoxRight}>
                  //     <div className={styles.applyResultBoxText1}>恭喜, <span className={styles.applyPrductionName}>{!channelValue?.includes('noMate') && !isMultiProduct && productName}</span>申请成功！</div>
                  //     <div className={styles.applyResultBoxText2}>请留意审核人员来电，完成信息审核</div>
                  //   </div>
                  // </>
                  }
                </div>
                <div className={classNames(styles.containers, styles.passBox)}>
                  <div className={styles.containerItem}>
                    <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/pass.png')} alt="pass" />
                    <div className={styles.containerItemSuccessText}>预审通过</div>
                  </div>
                  <img className={styles.line} src={require('@imgs/form-result/line.png')} alt="line" />
                  <div className={styles.containerItem}>
                    <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/phone.png')} alt="phone" />
                    <div className={styles.containerItemFailText}>请留意审核人员来电</div>
                  </div>
                </div>
              </div>
            </div>
          }
        </> : <></>
      }


      <JointLoginPopup ref={JointLoginModalRef}></JointLoginPopup>
    </div>
  )
}

export default SuccessPage
